"use client";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "./context/user-context";
import { DealershipContext } from "./context/dealership-context";
import Cookies from "js-cookie";
import { redirect, useRouter } from "next/navigation";
import {
  defaultDealershipPages,
  deliveryCalendarNew,
  salesLogNew,
  salesLogSalesPersonNew,
  salesPersonSalesLog,
  usersNewPage,
} from "../types";

import {
  IconCalendarCancel,
  IconChevronDown,
  IconPresentationAnalytics,
} from "@tabler/icons-react";

import NavLogo from "./nav-logo";
import { useClerk } from "@clerk/nextjs";
import Link from "next/link";
import {
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  ListItemPrefix,
  Menu,
  MenuHandler,
  MenuItem,
  Typography,
  Accordion,
} from "@material-tailwind/react";
import { Open_Sans } from "next/font/google";
import { featureFlags } from "./consts";

export const NavMenu = () => {
  const { user, userGroups, getPrimaryUserGroup } = useContext(UserContext);
  const { dealerships, currentDealership, setCurrentDealership, hasFeature } =
    useContext(DealershipContext);

  const { signOut } = useClerk();

  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const [isAdminOnCurrentStore, setIsAdminOnCurrentStore] =
    useState<boolean>(false);

  const [insightsToggled, setInsightsToggled] = useState<boolean>(false);

  const router = useRouter();

  const changeDealership = useCallback(
    (dealershipId: string) => {
      const dealership = dealerships.find(
        (dealership) => dealership.id === dealershipId,
      );

      if (!dealership) {
        return;
      }

      Cookies.set("dealershipId", dealershipId);
      setCurrentDealership(dealership);

      location.reload();
    },
    [dealerships, setCurrentDealership],
  );

  useEffect(() => {
    if (!user || !currentDealership) return;

    const found =
      userGroups &&
      userGroups.find((group) => group.dealershipId === currentDealership._id);
    if (typeof found === "undefined") return;

    setIsAdminOnCurrentStore(found.name === "Admin");
  }, [user, userGroups, currentDealership]);

  const primaryUserGroup = useMemo(() => {
    if (!currentDealership) return null;

    const primary = getPrimaryUserGroup(
      currentDealership._id ?? currentDealership.id,
    );
    return primary;
  }, [currentDealership, getPrimaryUserGroup]);

  useEffect(() => {
    if (!currentDealership || !user) {
      return;
    }

    if (user.permissions && user.permissions[currentDealership.id]) {
      setUserPermissions(user.permissions[currentDealership.id]);
      return;
    }

    if (!primaryUserGroup) {
      return;
    }

    setUserPermissions(primaryUserGroup.permissions);
  }, [primaryUserGroup, user, currentDealership, setUserPermissions]);

  const dealershipPages = useMemo(() => {
    if (!currentDealership) return [];

    const modifiedNavPages = defaultDealershipPages;

    modifiedNavPages["users-new"] = usersNewPage;

    if (hasFeature("SALES_LOG_V2")) {
      if (
        (userPermissions && userPermissions.includes("VIEW_SALES_LOG_FULL")) ||
        userPermissions.includes("VIEW_SALES_LOG_PARTIAL")
      ) {
        modifiedNavPages["sales-log"] = salesLogNew;
        modifiedNavPages["sp-saleslog"] = salesLogSalesPersonNew;
      }
    }

    if (hasFeature("DELIVERY_CALENDAR_V2")) {
      modifiedNavPages["delivery-calendar"] = deliveryCalendarNew;
    }

    return Object.values(modifiedNavPages);
  }, [currentDealership, hasFeature, userPermissions]);

  if (!user || !currentDealership || !dealershipPages) {
    return <></>;
  }

  return (
    <>
      <nav className="flex w-[250px] bg-white flex-col border-r shrink-0 h-screen z-[300]">
        <div className="flex flex-col h-full justify-between">
          <div>
            <div className="flex justify-center w-full mt-7">
              <div className="object-cover pb-7">
                <NavLogo width={"200px"} />
              </div>
            </div>
            <div>
              <ul className="flex flex-col pl-6 gap-4">
                {userPermissions &&
                  dealershipPages
                    .filter(
                      (page) =>
                        userPermissions.includes(page.permission!) ||
                        !page.permission,
                    )
                    .map((page) => {
                      return (
                        <li key={page.name} className="pl-2 mr-2 flex flex-row">
                          <Link
                            href={page.path}
                            className="transition-colors w-full rounded text-[#6e84a3] text-sm hover:text-black font-medium leading-6 font-sans hover:bg-blue-gray-50 hover:bg-opacity-80 focus:bg-blue-gray-50 focus:bg-opacity-80 active:bg-blue-gray-50 active:bg-opacity-80 hover:text-blue-gray-900 focus:text-blue-gray-900 active:text-blue-gray-900"
                          >
                            <i className={`fal ${page.icon} fa-fw mr-2`}></i>{" "}
                            {page.name}
                          </Link>
                        </li>
                      );
                    })}
                {hasFeature("INSIGHTS") && (
                  <li key={"insights"} className="w-full pl-2">
                    <List className="m-0 min-w-full max-w-full -ml-2 p-0 pl-2">
                      <Accordion
                        open={insightsToggled}
                        className="ml-0"
                        icon={
                          <IconChevronDown
                            strokeWidth={1}
                            className={`transition-transform -ml-4 ${insightsToggled ? "rotate-180" : ""}`}
                          />
                        }
                      >
                        <ListItem
                          className="p-0 flex flex-row leading-6 my-0 py-0 min-w-full rounded"
                          selected={insightsToggled}
                        >
                          <AccordionHeader
                            onClick={() => setInsightsToggled(!insightsToggled)}
                            className="min-w-full my-0 py-0 border-b-0 flex flex-row text-[#6e84a3] text-sm hover:text-black leading-6 font-medium justify-start subpixel-antialiased"
                          >
                            <ListItemPrefix className="mr-2 ml-0">
                              <IconPresentationAnalytics
                                size={20}
                                strokeWidth={1}
                              />
                            </ListItemPrefix>
                            Insights
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody>
                          <List className="p-0">
                            <Link href={"/insights/cancellations"}>
                              <ListItem className="text-xs px-2 py-2 ml-4 rounded">
                                <ListItemPrefix>
                                  <IconCalendarCancel size={15} />
                                </ListItemPrefix>
                                Cancellations
                              </ListItem>
                            </Link>
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </List>
                  </li>
                )}

                {dealerships && dealerships.length > 1 ? (
                  <li className="pl-2 group  relative dropdown text-[#6e84a3] text-sm hover:text-black leading-6 font-medium">
                    <a className="nav-link" href="#">
                      <i className={`fal fa-home fa-fw mr-2`}></i> My
                      Dealerships
                    </a>

                    <div className="group-hover:block dropdown-menu hidden h-auto">
                      <ul className="top-0 bg-white shadow w-full">
                        {currentDealership &&
                          dealerships.map((dealership) => {
                            return (
                              <li
                                onClick={() => {
                                  changeDealership(dealership.id);
                                }}
                                key={dealership.id}
                                className="cursor-pointer py-3 pl-2 flex flex-row items-center"
                              >
                                {" "}
                                {currentDealership!.id === dealership.id ? (
                                  <i
                                    style={{
                                      color: "#42f58d",
                                    }}
                                    className="fas fa-check-circle font-size-sm ml-0 pr-2"
                                  >
                                    {" "}
                                  </i>
                                ) : (
                                  <></>
                                )}{" "}
                                <a
                                  role="button"
                                  className="block text-sm text-[#6e84a3] "
                                >
                                  {dealership.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>

          <div className="flex flex-col text-center pb-6">
            <div className="pb-4 pl-6 pr-6 pt-6 text-gray-500 text-sm border-t">
              {user && user.name}
            </div>
            {dealerships && dealerships.length > 1 && isAdminOnCurrentStore ? (
              <button
                type="button"
                onClick={() => {
                  router.push("/multi-store");
                }}
                className="text-[#43d662] hover:text-white border border-[#43d662] p-2 hover:bg-[#43d662] focus:ring-4 focus:outline-none focus:ring-[#43d662] font-medium rounded-lg text-sm text-center ml-4 mr-4 mb-2"
              >
                Executive View
              </button>
            ) : (
              <></>
            )}
            <button
              onClick={async () => {
                Cookies.remove("dealershipId", { path: "/" });
                await signOut({ redirectUrl: "/v2/sign-in" });
              }}
              type="button"
              className="text-red-600 hover:text-white border border-red-600 p-2 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm text-center  dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 ml-4 mr-4"
            >
              Sign out
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavMenu;
